<template>
  <div class="chats">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__chats") }}</h2>

      <div class="row">
        <div class="col-md-4 mb-3" >
          <b-card :header="$t('__chooseAnAgency')" >
            <b-list-group class="agency-list" @scroll="handleScroll">
              <b-list-group-item>
                  <div class="input-group rounded-pill">
                    <input
                      v-model="search"
                      type="text"
                      class="form-control rounded-pill"
                      :placeholder="$t('__searchAgentName')"
                      aria-label="search"
                      aria-describedby="search"
                    />
                    <button
                      type="button"
                      class="btn btn-link px-2 text-dark"
                      @click="searchAgency"
                    >
                      <i class="bi bi-search"></i>
                    </button>
                    <button
                      v-if="search !== null"
                      type="button"
                      class="btn btn-primary px-3 ms-1 rounded-pill"
                      id="clearSearch"
                      @click="clearSearch"
                    >
                      {{ $t("__clearSearch") }}
                    </button>
                  </div>
              </b-list-group-item>
              <b-list-group-item
                v-for="agency in allAgencies"
                :key="agency.id"
                class="agency-item"
                @click="selectAgency(agency)"
              >
                <div class="agency-item-container d-flex flex-column">
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <div v-if="agency.online" class="dot online mr-2"></div>
                      <div v-else class="dot offline mr-2"></div>
                      <span>{{ agency.name }}</span>
                    </div>
                    <span v-if="agency.unread_count > 0" class="badge red">{{ agency.unread_count }}</span>
                  </div>

                  <div class="d-flex justify-content-between mt-1">
                    <div class="text-truncate">
                      <template v-if="agency.lastMessage && agency.lastMessage.type === 'context'">{{ agency.lastMessage.content }}</template>
                      <template v-if="agency.lastMessage && agency.lastMessage.type === 'image'">{{ $t('__chatsSendImage') }}</template>
                    </div>
                    <div v-if="agency.lastMessage" class="text-muted">
                      {{ agency.lastMessage.createdAt }}
                    </div>
                  </div>

                  <div v-if="agency.lastMessageStatus === 'pending'" class="mt-1 pending green badge">
                    <span class="badge badge-warning">{{ $t('__chatsPending') }}</span>
                  </div>
                </div>
              </b-list-group-item>

              <div  class="nomore-agency" v-if="canAgencyLoadMore === false">
                {{ $t("__chatsNoMore") }}
              </div>
            </b-list-group>
          </b-card>
        </div>

        <div class="col-md-8">
          <div class="card">
            <div class="card-header" v-if="Object.keys(nowAgency).length > 0">
              <div class="card-title">
                <h3>{{ nowAgency.name }}</h3>
              </div>
              <span v-if="nowAgency.online == true" class="label label-sm label-dot label-success">{{ $t('__chatsOnline') }}</span>
              <span v-else class="font-weight-bold text-muted font-size-sm">{{ $t('__chatsOffline') }}</span>
            </div>
            <div class="card-body">
              <div v-if="initialMessageLoad">
                <h2 class="text-center text-muted">{{ $t('__chatsSelectAgency') }}</h2>
              </div>
              <!--begin::Scroll-->
              <ul class="messages" v-show="!initialMessageLoad" @scroll="handleMessageScroll">
                <div v-if="!canMessageLoadMore && lastMessagePage > 1 && roomIsEmpty === false" class="loadingMore">{{ $t('__chatsNoMore') }}</div>

                <li v-for="(message) in messages" :key="message.id">
                  <div v-if="message.sender_type === 'agency' && message.sender_id == nowAgency.id" :class="['in', { 'pending': message.status === 'pending' }]">
                    <div class="message" >
                      <span class="body" v-if="message.type === 'image'">
                        <img :src="message.content" />
                      </span>
                      <span @contextmenu="handleRightClickMessage($event, message)" class="body" v-else>{{ message.content }}</span>
                    </div>
                    <span class="datetime">{{ message.createdAt }}</span>
                  </div>

                  <div v-else-if="message.sender_type === 'manager' && message.sender_id === userInfo.id" class="out">
                    <span class="datetime">
                      <template v-if="message.reads === 'read'">{{ $t('__chatsAgencyReads') }}<br /></template>
                      {{ message.createdAt }}
                    </span>
                    <div class="message">
                      <span class="body" v-if="message.type === 'context'">{{ message.content }}</span>
                      <span class="body" v-if="message.type === 'image'">
                        <img :src="message.content" />
                      </span>
                    </div>
                  </div>

                  <div v-else class="out manager">
                    <span class="datetime">
                      {{ managers[message.sender_id].name }}<br />
                      <template v-if="message.reads === 'read'">{{ $t('__chatsAgencyReads') }}<br /></template>
                      {{ message.createdAt }}
                    </span>
                    <div class="message">
                      <span class="body" v-if="message.type === 'image'">
                        <img :src="message.content" />
                      </span>
                      <span class="body" v-else>{{ message.content }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer" v-if="!initialMessageLoad">
              <div class="chat-form">
                <div class="input-cont">
                  <textarea v-model="message" @keydown.enter="sendMessage()" ref="messageTextarea" :disabled="sendMessageStatus" class="form-control border-0" rows="5" :placeholder="$t('__chatsInputPlaceHolder')" ></textarea>
                </div>
                <div class="btn-cont">
                  <input type="file" id="fileInput" @change="onFileChange" style="display: none" />

                  <button class="btn-image" @click="triggerFileInput()">
                    <i class="bi bi-image"></i>
                  </button>

                  <button class="btn-image" @click="sendMessage()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                      <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
                    </svg>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div :style="'top: ' + messageRightClick.top + 'px; left: ' + messageRightClick.left + 'px;'"
         id="rightClickMenu"
         v-show="messageRightClick.visible">
      <div class="rightClickOption"
           v-show="messageRightClick.message.type === 'context' && messageRightClick.message.status !== 'pending'"
           @click="markAsPending">{{ $t('__chatsMarkPending') }}</div>
      <div class="rightClickOption"
           v-show="messageRightClick.message.type === 'context' && messageRightClick.message.status === 'pending'"
           @click="markAsPending">{{ $t('__chatsMarkDone') }}</div>
      <div class="rightClickOption"
           v-show="messageRightClick.message.type === 'context'"
           @click="cancelMarks">{{ $t('__chatsMarkCancel') }}</div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import { mapState, mapMutations } from 'vuex'
import moment from "moment-timezone"
import Echo from 'laravel-echo'
import io from 'socket.io-client'
import { chat } from "@/http/api/chat";
import i18n from "@/lang/lang";

export default {
  name: 'Chats',
  data () {
    return {
      allAgencies: [],
      search: null,
      bootstrap_tooltip: null,
      canAgencyLoadMore: true,
      isAgencyLoading: false,
      initialMessageLoad: true,
      canMessageLoadMore: true,
      sendMessageStatus: false,
      roomIsEmpty: true,
      nowAgency: {},
      messages: [],
      lastMessageId: null,
      managers: [],
      message: null,
      agencyCurrentPage: 1,
      messageCurrentPage: 1,
      lastMessagePage: 1,
      messageRightClick: {
        top: 0,
        left: 0,
        visible: false,
        ifSelfMessage: false,
        message: {},
      },
      selectedImage: null,
      echoClient: null,
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      if (!this.permissions) return false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    }
  },
  watch: {
    modalData: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getAllAgency () {
      const vm = this
      const params = {
        search: vm.search,
        page: vm.agencyCurrentPage,
        per_page: 30
      };

      chat.agencies('', this.token, params).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          if (res.data.data.length === 0) {
            vm.canAgencyLoadMore = false
          } else {
            vm.allAgencies.push(...res.data.data)
            vm.agencyCurrentPage++
          }

          this.isAgencyLoading = false;
        }
      }).catch((err) => {
        console.log(err)
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        vm.Alerted()
      })
    },
    searchAgency () {
      if (this.search === null) {
        return
      }

      this.agencyCurrentPage = 1
      this.allAgencies = []
      this.getAllAgency()
    },
    clearSearch () {
      this.search = null
      this.agencyCurrentPage = 1
      this.allAgencies = []
      this.canAgencyLoadMore = true
      this.getAllAgency()
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    sendMessage () {
      const vm = this

      if (vm.message === '' || vm.message === null) {
        return
      }

      vm.sendMessageStatus = true

      chat.sendFromManager({
        agencyId: vm.nowAgency.id,
        content: vm.message
      }, vm.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.message = ''
          vm.sendMessageStatus = false
          vm.scrollMessagesToBottom()
          vm.$nextTick(() => {
            vm.$refs.messageTextarea.focus()
          });
        }
      }).catch(err => {
        vm.sendMessageStatus = true
        console.log(err)
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        vm.Alerted()
      })
    },
    messageSocket () {
      this.echoClient = new Echo({
        broadcaster: 'socket.io',
        host: 'https://' + window.location.hostname,
        client: io,
        options: {
          reconnection: true,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 30000,
          transports: ['websocket', 'polling']
        }
      })

      this.echoClient.connector.socket.on('disconnect', () => {
        console.log('socket disconnect')
      })

      this.echoClient.connector.socket.on('connect', () => {
        console.log('socket connect')
      })

      this.echoClient.channel('broadcast_database_chat.room')
        .listen('MessageReceivedEvent', (e) => {
          if (e.message.room_id === this.nowAgency.room_id) {
            this.messages.push(e.message);
            this.scrollMessagesToBottom()

            if (e.message.type === 'image') {
              this.scrollToImage()
            }

            let params = {
              roomId: this.nowAgency.room_id,
              agencyId: this.nowAgency.id
            }

            chat.reads(this.token, params).then(res => {
              if (res.status <= 201 && res.data.status === 'success') {
              }
            })
          }

          const agencyIndex = this.allAgencies.findIndex(a =>
            a.id == e.message.agency_id
          );

          if(agencyIndex !== -1) {
            if (e.message.sender_type === 'agency' && e.message.room_id !== this.nowAgency.room_id) {
              let currentUnreadCount = Number(this.allAgencies[agencyIndex].unread_count) || 0;
              this.$set(this.allAgencies[agencyIndex], 'unread_count', currentUnreadCount + 1);
            }

            if (!this.allAgencies[agencyIndex].lastMessage) {
              this.$set(this.allAgencies[agencyIndex], 'lastMessage', {});
            }

            let lastMessage = this.allAgencies[agencyIndex].lastMessage;
            this.$set(lastMessage, 'content', e.message.content);
            this.$set(lastMessage, 'createdAt', e.message.createdAt);
            this.$set(lastMessage, 'type', e.message.type);
          }
        });

      this.echoClient.channel('broadcast_database_online.status')
        .listen('OnlineStatusEvent', (e) => {
          if (e.status.status === 'online' || e.status.status === 'reads') {
            this.updateAgencyStatus(e.status.agency_id, true)
            if (e.status.status === 'reads' && e.status.agency_id === this.nowAgency.id) {
              this.messages.forEach(message => {
                if (message.reads !== 'read') {
                  message.reads = 'read';
                }
              })
            }
          }
        });
    },
    updateAgencyStatus (agencyId, onlineStatus) {
      const agencyIndex = this.allAgencies.findIndex(a => a.id === agencyId);
      if (agencyIndex !== -1) {
        this.$set(this.allAgencies[agencyIndex], 'online', onlineStatus);
      }
    },
    selectAgency (selectedAgency) {
      if (Object.keys(this.nowAgency).length > 0 && this.nowAgency.id !== selectedAgency.id) {
        this.nowAgency = selectedAgency;
        this.messages = []
        this.message = '';
        this.messageCurrentPage = 1;
        this.lastMessagePage = 1;
        this.lastMessageId = null;
        this.canMessageLoadMore = true;
        this.loadMessagesForAgency(selectedAgency.id);
      } else if (Object.keys(this.nowAgency).length === 0) {
        this.nowAgency = selectedAgency;
        this.loadMessagesForAgency(selectedAgency.id);
      }
    },
    loadMessagesForAgency (agencyId) {
      const vm = this;

      const params = {
        agencyId: agencyId,
        page: vm.messageCurrentPage,
        per_page: 25,
        lastId: vm.lastMessageId
      };

      chat.messages('', vm.token, params).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.initialMessageLoad = false
          vm.roomIsEmpty = false;

          if (res.data.data.data.length === 0) {
            vm.canMessageLoadMore = false;

            if (vm.messageCurrentPage === 1) {
              vm.roomIsEmpty = true
            }
          } else {
            vm.messages.unshift(...res.data.data.data.reverse())
            vm.lastMessageId = res.data.data.data[0].id
          }

          if (vm.messageCurrentPage === 1) {
            vm.$nextTick(() => {
              vm.nowAgency.unread_count = 0;

              setTimeout(() => {
                const messageContent = vm.$el.querySelector('.messages')
                if (messageContent) {
                  messageContent.scrollTop = messageContent.scrollHeight
                }
              }, 50)

              this.scrollToImage()
            })
          } else {
            const wrapper  = vm.$el.querySelector('.messages')
            const previousScrollHeight = wrapper.scrollHeight;
            const previousScrollTop = wrapper.scrollTop;
            this.$nextTick(() => {
              const newScrollHeight = wrapper.scrollHeight;
              wrapper.scrollTop = previousScrollTop + (newScrollHeight - previousScrollHeight);
            });
          }

          this.$nextTick(() => {
            this.$refs.messageTextarea.focus()
          })

          vm.messageCurrentPage++
          vm.lastMessagePage++
        }
      })
    },
    scrollMessagesToBottom () {
      this.$nextTick(() => {
        const messageContent = this.$el.querySelector('.messages')
        if (messageContent) {
          messageContent.scrollTop = messageContent.scrollHeight
        }
      })
    },
    handleScroll(event) {
      let wrapper = event.target;
      if (this.isAgencyLoading) return;

      if (this.canAgencyLoadMore && wrapper.scrollTop + wrapper.offsetHeight >= (wrapper.scrollHeight - 10)) {
        this.isAgencyLoading = true;
        this.getAllAgency();
      }
    },
    handleMessageScroll(event) {
      let wrapper = event.target;

      if (this.canMessageLoadMore && this.lastMessagePage > 1 && wrapper.scrollTop === 0) {
        this.loadMessagesForAgency(this.nowAgency.id);
      }
    },
    handleRightClickMessage(event, message, selfMessage = false) {
      event.preventDefault();
      this.messageRightClick = {
        top: event.clientY,
        left: event.clientX,
        visible: false,
        ifSelfMessage: selfMessage,
        message: message,
      };

      let clickMenu = document.getElementById('rightClickMenu');
      if (clickMenu) {
        this.$nextTick(() => {
          for (let child of clickMenu.children) {
            if (child.style.display !== 'none') {
              this.messageRightClick.visible = true;
            }
          }
        });
      }
    },
    markAsPending () {
      const nowStatus = this.messageRightClick.message.status

      chat.pending(this.token, { messageId: this.messageRightClick.message.id }).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const messageIndex = this.messages.findIndex(a => a.id === this.messageRightClick.message.id);
          const agencyIndex = this.allAgencies.findIndex(a => a.id === this.nowAgency.id);

          if (messageIndex !== -1 && agencyIndex !== -1) {
            if (nowStatus === 'pending') {
              this.$set(this.messages[messageIndex], 'status', 'show');
              this.$set(this.allAgencies[agencyIndex], 'lastMessageStatus', 'show');
            } else {
              this.$set(this.messages[messageIndex], 'status', 'pending');
              this.$set(this.allAgencies[agencyIndex], 'lastMessageStatus', 'pending');
            }
          }
        }
      })
      this.messageRightClick.visible = false;
    },
    cancelMarks () {
      this.messageRightClick.visible = false;
    },
    onFileChange(e) {
      this.selectedImage = e.target.files[0];
      if (this.selectedImage) {
        this.uploadImage();
        e.target.value = ''
      }
    },
    triggerFileInput() {
      document.getElementById('fileInput').click();
    },
    uploadImage() {
      this.Loading()
      this.sendMessageStatus = true

      const formData = new FormData();
      formData.append('image', this.selectedImage, this.selectedImage.name);
      formData.append('roomId', this.nowAgency.room_id);
      formData.append('agencyId', this.nowAgency.id);
      formData.append('storeFolder', 'chats');

      chat.sendImage(formData, this.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
        }
        this.selectedImage = null
        this.sendMessageStatus = false
        this.Loaded()
      }).catch(err => {
        this.selectedImage = null
        this.sendMessageStatus = false
        this.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        this.Alerted()
        this.Loaded()
      })
    },
    scrollToImage() {
      this.$nextTick(() => {
        let images = this.$el.querySelectorAll('.messages img');
        let loadedImageCount = 0;
        images.forEach((img) => {
          if (img.complete) {
            loadedImageCount++;
            if (loadedImageCount === images.length) {
              this.scrollMessagesToBottom();
            }
          } else {
            img.onload = () => {
              loadedImageCount++;
              if (loadedImageCount === images.length) {
                this.scrollMessagesToBottom();
              }
            };
          }
        });

        if (images.length === 0 || loadedImageCount === images.length) {
          this.scrollMessagesToBottom();
        }
      });
    },
    getManagers() {
      chat.managers('', this.token, {}).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          this.managers = res.data.data
        }
      })
    }
  },
  created () {
    this.getAllAgency()
    this.getManagers()
  },
  mounted () {
    this.messageSocket()
  },
  beforeDestroy() {
    if (this.echoClient) {
      this.echoClient.disconnect();
    }
  }
}
</script>

<style lang="scss">
.agency-list {
  max-height: 700px;
  overflow-y: auto;
}

.agency-item {
  cursor: pointer;
}

.agency-item .dot {
  width: 15px;
  height: 15px;
  border-radius: 50% !important;
  margin-right: 5px;
}

.agency-item .online {
  background: #36c6d3;
  color: #36c6d3;
}

.agency-item .offline {
  background: #B5B5C3 !important;
  color: #B5B5C3 !important;
}

.agency-item .badge {
  font-size: 12px;
  color: white;
}

.red {
  background: red;
}

.agency-item .pending {
  align-self: flex-end;
}

.green {
  background: #1BBC9B;
}

.messages {
  height: 700px;
  overflow-y: auto;
}

.chat-form .btn-cont {
  margin: 10px 0;
  text-align: right;
}

.chat-form .btn-send, .chat-form .btn-image {
  padding: 5px 10px;
  background: #00b4d8;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  margin-left: 10px;
}

.agency-list::-webkit-scrollbar {
  width: 10px;
}

.agency-list::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
  margin: 2px 0;
}

.agency-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.messages li {
  list-style: none;
  padding: 5px 0;
  margin: 0;
  font-size: 12px;
  display: flex;
  width: 100%;
}

.messages li .out,
.messages li .in {
  display: flex;
  width: 100%;
}

.messages li .out {
  margin-bottom: 5px;
  margin-right: 5px;
  justify-content: flex-end;
}

.messages li .in {
  justify-content: flex-start;
}

.messages li .out .message,
.messages li .in .message {
  background: #fafafa;
  text-align: left;
  position: relative;
  border-radius: 20px 20px 8px 20px;
  margin: 0;
  display: flex;
  align-items: flex-end;
  padding: 15px;
  max-width: 85%;
  font-size: 14px;
  font-weight: 500;
}

.messages li .out.manager .message {
  color: #ffffff;
  background: #1BBC9B;
}

.messages .message .body {
  white-space: pre-line;
  word-break: break-all;
  font-size: 16px;
}

.messages .datetime {
  align-items: end;
  display: flex;
  font-size: 10px;
  color: #999;
}

.messages .out .datetime {
  margin-right: 5px;
}

.messages .in .datetime {
  margin-left: 5px;
}

.messages .message .body img {
  width: 100%;
}

.messages li .in .message {
  text-align: left;
  border-radius: 20px 20px 20px 8px;
  color: #ffffff;
  margin-left: 0;
  background: #1BBC9B;
}

.messages li .in.pending .message {
  background: #FFCC00 !important;
}

.messages::-webkit-scrollbar {
  width: 10px;
}

.messages::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
  margin: 2px 0;
}

.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.loadingMore,
.nomore-agency {
  padding: 20px;
  text-align: center;
  color: #999999;
}

#rightClickMenu {
  position: fixed;
  background-color: white;
  border: 2.2px solid #DCDCDC;
  border-radius: 4px !important;
  line-height: 28px;
  padding: 4px 0;

}

.rightClickOption {
  padding: 0 20px;
}

.rightClickOption:hover {
  background-color: #FAFAFA;
  cursor: context-menu;
}
</style>
