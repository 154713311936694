import { axiosConfig } from '../api/axios-config.js'

export const chat = {
  messages: (id, token, additionalParams = {}) => {
    return axiosConfig.get(`/api/messages/${id || ''}`, { headers: token.headers, params: additionalParams });
  },

  // 更改已讀
  reads: (token, data) => axiosConfig.patch('/api/messages/reads', data, token),

  agencies: (id, token, additionalParams = {}) => {
    return axiosConfig.get('/api/messages/getAgencies', { headers: token.headers, params: additionalParams });
  },

  sendFromManager: (data, token) => axiosConfig.post('/api/messages/manager', data, token),

  pending: (token, data) => axiosConfig.patch('/api/messages/pending', data, token),

  sendImage: (data, token) => axiosConfig.post('/api/image', data, token),

  managers: (id, token, additionalParams = {}) => {
    return axiosConfig.get('/api/messages/managers', { headers: token.headers, params: additionalParams });
  },
}
